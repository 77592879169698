#root {
  height: 100%;
}

.App {
  text-align: center;
  margin: 0 auto;
  background: #f0f2f5;
  min-height: 100%;
  height: auto;
  padding-top: 50px;
  width: 90%;
  padding-bottom: 50px;
}